import { Controller } from "@hotwired/stimulus"
import { useMeta } from 'stimulus-use'

export default class extends Controller {
  static metaNames = ['userAdmin']

  connect() {
    useMeta(this)

    if (this.userAdminMeta === true) {
      this.element.classList.remove('d-none')
    }
  }
}
