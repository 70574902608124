import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ['spinner', 'button']

  connect() {
    this.hideSpinner();
  }

  showSpinner(event) {
    event.prevenDefault()
    if (this.hasSpinnerTarget) {
      this.spinnerTarget.style.display = 'block';
    }
    if (this.hasButtonTarget) {
      this.buttonTarget.classList.add('disabled');
      this.buttonTarget.dataset.action = '';
    }
  }

  hideSpinner() {
    if (this.hasSpinnerTarget) {
      this.spinnerTarget.style.display = 'none';
    }
    if (this.hasButtonTarget) {
      this.buttonTarget.classList.remove('disabled');
      this.buttonTarget.dataset.action = 'click->spinner#showSpinner';
    }
  }
}
