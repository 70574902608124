import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = { url: String }
  static targets = ["frame"]

  dutyChanged(event) {
    this.frameTarget.src = this.urlValue + "?duty=" + event.target.value
    this.frameTarget.reload()
  }
}

