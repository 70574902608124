import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ["fieldSelector", "choicesField", "multipleField"]

  connect() {
    this.fieldTypeSelected()
  }

  fieldTypeSelected() {
    if (this.fieldSelectorTarget.value == 'SelectField') {
      this.choicesFieldTarget.style.display = 'block'
      this.multipleFieldTarget.style.display = 'block'
    } else {
      this.choicesFieldTarget.style.display = 'none'
      this.multipleFieldTarget.style.display = 'none'
    }
  }
}
