import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  choose(event) {
    event.preventDefault()
    document.getElementById('onboarding_template_id').value = event.target.dataset.id
    document.querySelectorAll('#accordion-onboardings button.choose-template').forEach(function (element) {
      element.classList.remove('active')
    })
    document.querySelectorAll('#accordion-onboardings .card-header.template-selected').forEach(function (element) {
      element.classList.remove('template-selected')
    })
    event.target.classList.add('active')
    document.querySelector('#heading-onboarding-' + event.target.dataset.id).classList.add('template-selected')
    // document.querySelector('form.edit_onboarding').submit()
  }
}
