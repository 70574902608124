import Rails from '@rails/ujs'
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  updateAssignees(event) {
    event.preventDefault()
    if (event.target.classList.contains('aside-filter')) {
      return;
    }
    Rails.fire(event.target.closest('form'), 'submit')
  }
}
