import { Controller } from "@hotwired/stimulus"
import { Tooltip } from "bootstrap"


export default class extends Controller {
  connect() {
    const tooltipTriggerList = this.element.querySelectorAll('[data-bs-toggle="tooltip"]')
    const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => {
      const tooltip = new Tooltip(tooltipTriggerEl);
      tooltipTriggerEl.addEventListener("click", (event) => {
        tooltip.hide();
      });
      return tooltip;
    })
  }
}