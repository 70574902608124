import { Sortable, Plugins } from "@shopify/draggable"
import Rails from "@rails/ujs"
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    const sortable = new Sortable(this.element, {
      draggable: '.draggable',
      delay: 70,
      distance: 10,
      handle: '.drag-grip',
      mirror: {
        constrainDimensions: true,
      },
      plugins: [Plugins.ResizeMirror],
    })

    sortable.on('sortable:stop', (evt) => {
      console.log('sortable:stop', evt)
      let formData = new FormData()
      formData.append("duty[position]", evt.data.newIndex + 1)
      Rails.ajax({
        url: evt.data.dragEvent.source.dataset.url,
        type: "PATCH",
        data: formData,
        dataType: 'script',
        success: function (data) { },
        error: function (data) { }
      })
    })
  }
}
