import Rails from '@rails/ujs'
import { Controller } from '@hotwired/stimulus'
import bsCustomFileInput from 'bs-custom-file-input'


export default class extends Controller {
  connect() {
    bsCustomFileInput.init()
  }

  duInitialize(event) {
    const { target, detail } = event
    const { id, file } = detail
    target.closest('.custom-file').querySelector('.uploads-wrapper').insertAdjacentHTML("afterbegin", `
      <div id="direct-upload-${id}" class="direct-upload direct-upload--pending">
        <div id="direct-upload-progress-${id}" class="direct-upload__progress" style="width: 0%"></div>
        <span class="direct-upload__filename"></span>
      </div>
    `)
    target.closest('.custom-file').querySelector('.uploads-wrapper').querySelector(`.direct-upload__filename`).textContent = file.name
  }

  duStart(event) {
    const { id } = event.detail
    const element = document.getElementById(`direct-upload-${id}`)
    element.classList.remove("direct-upload--pending")
  }

  duProgress(event) {
    const { id, progress } = event.detail
    const progressElement = document.getElementById(`direct-upload-progress-${id}`)
    progressElement.style.width = `${progress}%`
  }

  duError(event) {
    event.preventDefault()
    const { id, error } = event.detail
    const element = document.getElementById(`direct-upload-${id}`)
    element.classList.add("direct-upload--error")
    element.setAttribute("title", error)
  }

  duEnd(event) {
    const { id } = event.detail
    const element = document.getElementById(`direct-upload-${id}`)
    element.classList.add("direct-upload--complete")
  }

  disconnect() {
    bsCustomFileInput.destroy()
  }

  selected(event) {
    Rails.fire(event.target.closest('form'), 'submit')
  }
}
