import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = { url: String }
  static targets = ["frame"]

  connect() {
    console.log('hello')
  }

  contractTypeChanged(event) {
    const contractTypeId = event.target.value
    this.frameTarget.src = this.urlValue + '/' + contractTypeId;
    console.log(this.frameTarget.src)
    this.frameTarget.reload()
  }
}
