import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  showComments() {
    var quoteRequestId = this.element.querySelector('.tab-pane.active').dataset.quoteRequestId
    var commentsToHide = document.querySelectorAll('#card-modal .quote-request-comments')
    Array.prototype.forEach.call(commentsToHide, function (el, i) {
      el.classList.remove('active')
    })
    var commentsToShow = document.querySelector('#card-modal .quote-request-comments[data-quote-request="' + quoteRequestId + '"]')
    commentsToShow.classList.add('active')
  }
}
