import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["selectField", "frame"]
  static values = { url: String }

  linkableNotificationContentChanged(event) {
    this.frameTarget.src = this.urlValue + '?linkable_notification_content_id=' + event.currentTarget.value
    this.frameTarget.reload()
  }

  linkableNotificationContentModified(event) {
    this.selectFieldTarget.value = ""
  }
}
