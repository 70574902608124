import { Controller } from '@hotwired/stimulus'
import EditorJS from '@editorjs/editorjs'
import ImageTool from '@editorjs/image'
import Underline from '@editorjs/underline'
import List from '@editorjs/list'
import DragDrop from 'editorjs-drag-drop'
import Carousel from '../editorjs-blocks/carousel/dist/bundle.js'
import ServerBackedOembed from '../editorjs-blocks/server_backed_oembed'
import Flexframe from '../editorjs-blocks/flexframe'
import ToggleBlock from 'editorjs-toggle-block'


const Paragraph = require('@editorjs/paragraph')
const LinkTool = require('@editorjs/link')
const Hyperlink = require('editorjs-hyperlink')
const Embed = require('@Belibaste/embed')
const Table = require('@editorjs/table')
const Warning = require('@editorjs/warning')
const AttachesTool = require('@Belibaste/attaches')
const Quote = require('@editorjs/quote')
const Header = require('@editorjs/header')
const Delimiter = require('@editorjs/delimiter')
const RawTool = require('@editorjs/raw')
const AnyButton = require('editorjs-button')
const InlineCode = require('@editorjs/inline-code')
const Alert = require('editorjs-alert')
const ColorPlugin = require('editorjs-text-color-plugin')
const AlignmentTuneTool = require('editorjs-text-alignment-blocktune')

export default class extends Controller {
  connect(event) {
    this.editor = new EditorJS({
      holder: this.element.querySelector('#editorjs'),
      data: JSON.parse(this.element.querySelector('#editorjs_content').value || '{}'),
      autofocus: false,
      placeholder: 'Commencer la rédaction du contenu ici !',
      hideToolbar: false,
      onReady: () => {
        new DragDrop(this.editor)
      },
      tools: {
        paragraph: {
          class: Paragraph,
          inlineToolbar: ['bold', 'italic', 'underline', 'hyperlink', 'inlineCode', 'color', 'marker'],
          tunes: ['anyTuneName'],
          config: {
            placeholder: 'Saisissez votre contenu ici, ou cliquez le "+" pour ajouter un autre type de bloc',
            preserveBlank: true
          }
        },
        header: {
          class: Header,
          tunes: ['anyTuneName']
        },
        anyTuneName: {
          class: AlignmentTuneTool,
          config: {
            default: 'left',
            blocks: {
              header: 'center',
              list: 'right'
            }
          },
        },
        hyperlink: {
          class: Hyperlink,
          config: {
            target: '_blank',
            availableTargets: ['_blank', '_self'],
            availableRels: null,
            validate: true,
          }
        },
        alert: Alert,
        table: { class: Table, inlineToolbar: true },
        underline: Underline,
        warning: Warning,
        serverBackedOembed: ServerBackedOembed,
        flexframe: Flexframe,
        delimiter: Delimiter,
        quote: Quote,
        color: {
          class: ColorPlugin,
          config: {
            colorCollections: ['#FF1300', '#EC7878', '#9C27B0', '#673AB7', '#3F51B5', '#0070FF', '#03A9F4', '#00BCD4', '#4CAF50', '#8BC34A', '#CDDC39', '#FFF'],
            defaultColor: '#FF1300',
            type: 'text',
          }
        },
        marker: {
          class: ColorPlugin,
          config: {
            colorCollections: ['#FF1300', '#EC7878', '#9C27B0', '#673AB7', '#3F51B5', '#0070FF', '#03A9F4', '#00BCD4', '#4CAF50', '#8BC34A', '#CDDC39', '#FFF'],
            defaultColor: '#FFBF00',
            type: 'marker',
          }
        },
        raw: RawTool,
        inlineCode: {
          class: InlineCode
        },
        linkTool: {
          class: LinkTool,
          config: {
            endpoint: '/editor-blocks/link_tool_backend',
          }
        },
        list: { class: List, inlineToolbar: true },
        anyButton: {
          class: AnyButton,
          inlineToolbar: false,
          config: {
            css: {
              'btnColor': 'ejs-btn-primary',
              'btn': 'ejs-btn',
            }
          }
        },
        image: {
          class: ImageTool,
          config: {
            endpoints: {
              byFile: '/editor-blocks/upload-image-by-file',
              byUrl: '/editor-blocks/upload-image-by-url',
            },
            additionalRequestData: { authenticity_token: document.querySelector('meta[name="csrf-token"]').content }
          }
        },
        attaches: {
          class: AttachesTool,
          config: {
            endpoint: '/editor-blocks/upload-file-by-file',
            additionalRequestData: { authenticity_token: document.querySelector('meta[name="csrf-token"]').content }
          },
          // toolbox: [{
          //   icon: '<svg> ... </svg>',
          //   title: 'Fichier à afficher',
          // }],
        },
        attachesDownload: {
          class: AttachesTool,
          config: {
            endpoint: '/editor-blocks/upload-file-by-file',
            additionalRequestData: { authenticity_token: document.querySelector('meta[name="csrf-token"]').content }
          },
          toolbox: [{
            icon: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><!--! Font Awesome Pro 6.2.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M216 342.1V240c0-13.25-10.75-24-24-24S168 226.8 168 240v102.1L128.1 303C124.3 298.3 118.2 296 112 296S99.72 298.3 95.03 303c-9.375 9.375-9.375 24.56 0 33.94l80 80c9.375 9.375 24.56 9.375 33.94 0l80-80c9.375-9.375 9.375-24.56 0-33.94s-24.56-9.375-33.94 0L216 342.1zM365.3 93.38l-74.63-74.64C278.6 6.742 262.3 0 245.4 0H64C28.65 0 0 28.65 0 64l.0065 384c0 35.34 28.65 64 64 64H320c35.2 0 64-28.8 64-64V138.6C384 121.7 377.3 105.4 365.3 93.38zM336 448c0 8.836-7.164 16-16 16H64.02c-8.838 0-16-7.164-16-16L48 64.13c0-8.836 7.164-16 16-16h160L224 128c0 17.67 14.33 32 32 32h79.1V448z"/></svg>',
            title: 'Fichier à télécharger',
          }],
        },
        carousel: {
          class: Carousel,
          config: {
            endpoints: {
              byFile: '/editor-blocks/upload-image-by-file',
            },
            additionalRequestData: { authenticity_token: document.querySelector('meta[name="csrf-token"]').content }
          }
        }
      },
      i18n: {
        messages: {
          ui: { 'Filter': 'Filtre sur les outils' },
          tools: {
            'AnyButton': {
              'Button Text': 'Texte du bouton',
              'Link Url': 'Lien du bouton',
              'Set': 'Enregistrer',
              'Default Button': 'Bouton par défaut',
            },
            hyperlink: {
              Save: 'Enregistrer',
              'Select target': 'Sélectionner la cible',
              '_blank': 'Nouvelle fenêtre',
            }
          },
          toolNames: {
            'Link': 'Lien',
            'Text': 'Texte',
            'Heading': 'Titre',
            'Alert': 'Alerte',
            'Warning': 'Avertissement',
            'Table': 'Tableau',
            'Delimiter': 'Séparateur',
            'Quote': 'Citation',
            'Raw HTML': 'HTML brut',
            'List': 'Liste',
            'Button': 'Bouton',
            'Image': 'Image',
            'Attaches': 'Fichier à afficher',
            'attachesDownload': 'Fichier à télécharger',
            'Carousel': 'Carrousel d\'images',
          },
          blockTunes: {}
        },
      }
    })
  }

  save() {
    this.editor.save().then((outputData) => {
      console.log('Article data: ', outputData)
      document.getElementById('editorjs_content').value = JSON.stringify(outputData)
    }).catch((error) => {
      console.log('Saving failed: ', error)
    });
  }
}
