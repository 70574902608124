import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['form']

  connect() {
    $('.filters .dropdown').on('hide.bs.dropdown', function (e) {
      if (e.clickEvent && (e.clickEvent.target.tagName == 'LABEL' || e.clickEvent.target.tagName == 'INPUT')) {
        e.stopPropagation()
        e.preventDefault()
        return false
      }
    })
  }

  change(event) {
    this.formTarget.requestSubmit()
  }
}
