import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect() {
    const myDropdown = document.querySelector('.filters .dropdown')
    myDropdown.addEventListener('hide.bs.dropdown', e => {
      if (e.clickEvent && (e.clickEvent.target.tagName == 'LABEL' || e.clickEvent.target.tagName == 'INPUT')) {
        e.stopPropagation()
        e.preventDefault()
        return false
      }
    })
  }

  change(event) {
    var formData = new FormData(this.element)
    var selectedFilter = 0
    var selectedCard = new Array()
    for (var pair of formData.entries()) {
      if (pair[0] == 'employees-search') {
        continue
      }
      if (pair[1]) {
        selectedFilter += 1

        if (selectedFilter === 1) {
          document.querySelectorAll('.one-ticket').forEach(function (elem) {
            elem.style.display = 'none'
          })
        }

        var formKey = pair[0].substring(0, pair[0].length - 2)
        if (selectedCard[formKey] === undefined) {
          selectedCard[formKey] = new Array()
        }
        selectedCard[formKey].push(Array.from(document.querySelectorAll('.one-ticket[data-' + formKey + '~="' + pair[1] + '"]')))
        selectedCard[formKey].flat()
      }
    }

    if (selectedFilter === 0) {
      document.querySelectorAll('.one-ticket').forEach(function (elem) {
        elem.style.display = 'block'
      })
    } else {
      var result = []
      var tempo = []

      for (const [key, value] of Object.entries(selectedCard)) {
        tempo.push(Array.from(value.flat()))
      }

      for (var i = 0; i < tempo.length; i++) {
        var currentList = tempo[i]
        for (var y = 0; y < currentList.length; y++) {
          var currentValue = currentList[y]
          if (result.indexOf(currentValue) === -1) {
            if (tempo.filter(function (obj) { return obj.indexOf(currentValue) == -1 }).length == 0) {
              result.push(currentValue)
            }
          }
        }
      }
      result.forEach(function (elem) {
        elem.style.display = 'block'
      })
    }

    var count = event.target.closest('.dropdown-menu').querySelectorAll('input:checked').length
    event.target.closest('.dropdown').querySelector('.badge').innerHTML = count > 0 ? count : ''
  }
}
