import Rails from '@rails/ujs'
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  rootFilter() {
    event.preventDefault()
    this.filterProducts(event.target.dataset.categoryId)
    this.filterChildCategories(event.target.dataset.categoryId)
  }

  childFilter() {
    event.preventDefault()
    this.filterProducts(event.target.dataset.categoryId)
  }

  showModal(event) {
    event.preventDefault()
    Rails.ajax({
      type: "get",
      url: event.currentTarget.href,
      dataType: 'script',
      success: function (data) { },
      error: function (data) { }
    })
  }

  filterProducts(cat_id) {
    document.querySelectorAll('.one-product').forEach(elem => { if (elem.dataset.categoryId != "all") elem.style.display = "none" })
    document.querySelectorAll('.one-product[data-category-id="' + event.target.dataset.categoryId + '"], .one-product[data-root-category-id="' + event.target.dataset.categoryId + '"]').forEach(elem => { elem.style.display = "block"; })
  }

  filterChildCategories(cat_id) {
    document.querySelectorAll('.children-container').forEach(elem => { elem.style.display = "inline-block" })
    document.querySelectorAll('.children-category').forEach(elem => { elem.style.display = "none" })
    document.querySelectorAll('.children-category[data-root-category-id="' + cat_id + '"]').forEach(elem => { elem.style.display = "block"; })
  }
}
