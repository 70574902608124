import { Application } from "@hotwired/stimulus"
import "chartkick/chart.js"
import ApexCharts from 'apexcharts'
import Sortable from 'stimulus-sortable'

const application = Application.start()
application.register('sortable', Sortable)

// Configure Stimulus development experience
application.debug = true
window.Stimulus = application

window.ApexCharts = ApexCharts

export { application }
