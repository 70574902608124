import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "elementToReveal" ]

  toggleVisibility(event) {
    event.preventDefault()
    if(event.target.checked) {
      this.elementToRevealTarget.classList.remove('d-none')
      this.elementToRevealTarget.querySelectorAll('input').forEach(element => {
        element.disabled = false
      });
    } else {
      this.elementToRevealTarget.classList.add('d-none')
      this.elementToRevealTarget.querySelectorAll('input').forEach(element => {
        element.disabled = true
      });
    }
  }
}
