import { StreamActions } from "@hotwired/turbo";

StreamActions.confetti_close = function() {
  function random(max) {
    return Math.random() * max;
  }
  const button = document.querySelector('#duty-state-changer');

  const confettiContainer = document.createDocumentFragment();

  for (let i = 0; i < 100; i++) {
    const styles = `transform: translate3d(${random(500) - 250}px, ${random(200) - 150}px, 0) rotate(${random(360)}deg);\
                    background: hsla(${random(360)}, 100%, 50%, 1);\
                    animation: bang 700ms ease-out forwards;\
                    opacity: 0`;

    const confetti = document.createElement('i');
    confetti.style.cssText = styles;
    confettiContainer.appendChild(confetti);
  }

  button.appendChild(confettiContainer);

  setTimeout(() => {
    if (window.SideloaderControllerInstance) {
      window.SideloaderControllerInstance.closeSideLoader();
    }

    setTimeout(() => {
      const headerImage = document.querySelector('.confetti-image');
      headerImage.src = `${window.location.origin}/confettis.gif`;
    }, 700);
  }, 700);
}
