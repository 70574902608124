import { Controller } from "stimulus";
import { FetchRequest } from '@rails/request.js'

export default class extends Controller {
  static values = {
    integrationKey: String,
    signingUrl: String,
    dutyUrl: String
  }

  connect() {
    this.loadDocuSign();
  }

  selectTemplate(event) {
    console.log('selectTemplate');
    console.log(event);
  }

  async loadDocuSign() {
    if (!this.signingUrlValue) {
      console.error('DocuSign signing URL is missing.');
      return;
    }


    await window.DocuSign.loadDocuSign(this.integrationKeyValue).then((docusign) => {
      const signing = docusign.signing({
        url: this.signingUrlValue,
        displayFormat: 'focused'
      });

      signing.on('ready', (event) => {
        const loadingElement = document.getElementById('loading-container');
        if (loadingElement) loadingElement.style.display = 'none';
      })

      signing.on('sessionEnd', async (event) => {
        console.log('sessionend', event.sessionEndType);

        if (event.sessionEndType == 'signing_complete') {
          const frame = document.querySelector('#docusign-agreement');
          frame.innerHTML = '<turbo-stream action="replace" target="docusign-agreement"><template></template></turbo-stream>';

          const congratulationsMessage = document.getElementById('congratulations-message');
          if (congratulationsMessage) {
            congratulationsMessage.style.display = 'block';
          }

          const markAsDoneButton = document.getElementById('mark-as-done-button');
          if (markAsDoneButton) {
            markAsDoneButton.style.display = 'block';
          }
        }
      });

      signing.mount('#agreement');

    }).catch((ex) => {
      console.error('Error loading DocuSign:', ex);
    });
  }
}
