import { Sortable, Plugins } from "@shopify/draggable"
import Rails from "@rails/ujs"
import { Controller } from "@hotwired/stimulus"
import morphdom from 'morphdom'
import CableReady from "cable_ready"
import consumer from "../channels/consumer"

export default class extends Controller {
  connect() {
    const containers = document.querySelectorAll('.kanban-list-body');
    const sortable = new Sortable(containers, {
      draggable: '.is-draggable',
      handle: '.drag-handle',
      delay: {
        mouse: 0,
        drag: 0,
        touch: 0,
      },
      distance: 3,
      mirror: {
        constrainDimensions: true,
      },
      scrollable: {
        speed: 30,
        sensitivity: 400,
        scrollableElements: [],
      },
      plugins: [Plugins.ResizeMirror],
    })

    sortable.on('sortable:stop', (evt) => {
      let formData = new FormData()
      formData.append("card[position]", evt.data.newIndex + 1)
      formData.append("card[list_id]", evt.data.newContainer.parentElement.dataset.id)
      Rails.ajax({
        url: evt.data.dragEvent.source.dataset.url,
        type: "PATCH",
        data: formData,
        dataType: 'script',
        success: function (data) { },
        error: function (data) { }
      })
    })
    const board_id = this.data.get("id")
    this.boardChannel = consumer.subscriptions.create({ channel: "KanbanChannel", kanban: board_id }, {
      received(data) {
        if (data.cableReady) CableReady.perform(data.operations)
      }
    })
    document.querySelectorAll('[data-toggle="tooltip"]').forEach((element) => {
      new window.bootstrap.Tooltip(element).show()
    })

    // Open card on load if params in url
    var parsedUrl = new URL(window.location.href)
    if (parsedUrl.searchParams.get("card") != null || parsedUrl.searchParams.get("card") != '') {
      var id = parsedUrl.searchParams.get("card")
      var element = document.querySelectorAll('.kanban-card[data-id="' + id + '"]')[0]
      if (element) {
        this.showModal({ currentTarget: element, preventDefault: function () { return null; } })
      }
    }
  }

  reload(event) {
    Rails.ajax({
      type: 'get',
      url: event.detail.url,
      dataType: 'script',
      success: function (data) {
        const template = document.createElement('template')
        template.innerHTML = String(data).trim()
        morphdom(event.target, template.content, { childrenOnly: true })
        document.querySelector('.filters form input[type="checkbox"]').dispatchEvent(new Event('change', { 'bubbles': true })) // Apply filters
        const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]')
        const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new bootstrap.Tooltip(tooltipTriggerEl))
      },
      error: function (data) { }
    })
  }

  disconnect() {
    this.boardChannel.unsubscribe()
  }

  showModal(event) {
    event.preventDefault()
    Rails.ajax({
      type: "get",
      url: event.currentTarget.href,
      dataType: 'script',
      success: function (data) {
        const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]')
        const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new bootstrap.Tooltip(tooltipTriggerEl))
        Array.from(document.querySelector("#card-modal").querySelectorAll("script")).forEach(oldScript => {
          const newScript = document.createElement("script");
          Array.from(oldScript.attributes).forEach(attr => newScript.setAttribute(attr.name, attr.value));
          newScript.appendChild(document.createTextNode(oldScript.innerHTML));
          oldScript.parentNode.replaceChild(newScript, oldScript);
        });
      },
      error: function (data) { }
    })
  }
}
