import { Controller } from "@hotwired/stimulus"
confettiCannon = require("canvas-confetti")

export default class extends Controller {
  static targets = ["modal"]

  connect() {
    this.modal = new bootstrap.Modal(this.modalTarget)
    this.modal.show()

    var end = Date.now() + (6 * 1000);

    (function frame() {
      confettiCannon({
        particleCount: 2,
        angle: 60,
        spread: 55,
        origin: { x: 0, y: 0.99 },
        zIndex: 10000
      });
      confettiCannon({
        particleCount: 2,
        angle: 120,
        spread: 55,
        origin: { x: 1, y: 0.99 },
        zIndex: 10000
      });

      if (Date.now() < end) {
        requestAnimationFrame(frame);
      }
    }());
  }
}
