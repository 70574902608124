import { Controller } from "@hotwired/stimulus"
import { Calendar } from '@fullcalendar/core'
import frLocale from '@fullcalendar/core/locales/fr';
import listPlugin from '@fullcalendar/list'
import timeGridPlugin from '@fullcalendar/timegrid'
import bootstrap5Plugin from '@fullcalendar/bootstrap5';

export default class extends Controller {
  static targets = ["placeholder"]
  static values = {
    view: String,
    url: String,
    startDate: String,
  }

  connect() {
    this.calendar = new Calendar(this.placeholderTarget, {
      plugins: [listPlugin, timeGridPlugin, bootstrap5Plugin],
      locales: [frLocale],
      themeSystem: 'bootstrap5',
      initialView: this.viewValue,
      // aspectRatio: 1.5,
      // contentHeight: 500,
      height: 'auto',
      // expandRows: true,
      headerToolbar: {
        left: 'prev title next',
        center: '',
        end: 'listYear,timeGridDay'
      },
      locale: document.documentElement.lang,
      fixedWeekCount: false,
      slotMinTime: "08:00:00",
      slotMaxTime: "20:00:00",
      weekends: false,
      allDaySlot: false,
      firstDay: 1,
      dayHeaders: false,
      events: this.urlValue,
      initialDate: this.startDateValue,
      eventBackgroundColor: 'red',
      buttonIcons: { prev: 'arrow-left-square-fill', next: 'arrow-right-square-fill' },
      eventDidMount: function (info) {
        switch (info.view.type) {
          case 'listDay':
          case 'listWeek':
          case 'listMonth':
          case 'listYear':
            info.el.querySelector('a').dataset.turboFrame = 'sideloader'
            info.el.querySelector('a').dataset.action = 'click->sideloader#openSideLoader'
            break
          default:
            info.el.dataset.turboFrame = 'sideloader'
            info.el.dataset.action = 'click->sideloader#openSideLoader'
            break
        }

      },
      eventClick: function (info) {
        info.jsEvent.preventDefault()
        if (info.event.url) {
          document.querySelector('#sideloader').src = info.event.url
        }
      }
    })
    this.calendar.render();
    window.calendar = this.calendar
  }

  reloadEvents() {
    this.calendar.refetchEvents()
  }
}
