import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs"

export default class extends Controller {
  connect() {
    // Notification via Web push API
    navigator.serviceWorker.ready
      .then(function (registration) {
        console.log('service worker registered');
        document.querySelector('.js-webpush-button').removeAttribute('disabled')

        return registration.pushManager.getSubscription()
      }).then(function (subscription) {
        if (subscription) {
          console.log('Already subscribed', subscription.endpoint)
          document.querySelector('.js-webpush-button').textContent = 'Désactiver les notifications systèmes'
          document.querySelector('.js-webpush-button').dataset.substate = 'suscribed'
        } else {
          console.log('Not subscribed', subscription)
          document.querySelector('.js-webpush-button').textContent = 'Activer les notifications systèmes'
          document.querySelector('.js-webpush-button').dataset.substate = 'notsuscribed'
        }
      });
  }

  webpushSubscription(event) {
    event.preventDefault()

    if (document.querySelector('.js-webpush-button').dataset.substate == 'suscribed') {
      navigator.serviceWorker.ready
        .then(function (registration) {
          return registration.pushManager.getSubscription()
        }).then(function (subscription) {
          return subscription.unsubscribe()
            .then(function () {
              console.log('Unsubscribed', subscription.endpoint)
              return fetch(event.target.dataset.href, {
                method: 'delete',
                headers: {
                  'Content-type': 'application/json',
                  'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
                },
                credentials: 'same-origin',
                body: JSON.stringify({
                  _method: 'delete',
                  webpush_subscription: { subscription: subscription }
                })
              })
            })
        }).then(function (temp) {
          document.querySelector('.js-webpush-button').textContent = 'Activer les notifications systèmes'
          document.querySelector('.js-webpush-button').dataset.substate = 'notsuscribed'
        })
    } else {
      navigator.serviceWorker.ready
        .then(async function (registration) {
          const convertedVapidKey = new Uint8Array(window.vapidPublicKey)
          return registration.pushManager.subscribe({
            userVisibleOnly: true,
            applicationServerKey: convertedVapidKey
          });
        }).then(function (subscription) {
          console.log('Subscribed', subscription.endpoint);
          return fetch(event.target.dataset.href, {
            method: 'put',
            headers: {
              'Content-type': 'application/json',
              'Accept': 'application/json',
              'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
            },
            credentials: 'same-origin',
            body: JSON.stringify({
              _method: 'put',
              webpush_subscription: { subscription: subscription }
            })
          });
        }).then(function (temp) {
          document.querySelector('.js-webpush-button').textContent = 'Désactiver les notifications systèmes'
          document.querySelector('.js-webpush-button').dataset.substate = 'suscribed'
        })
    }
  }

  webpushTest(event) {
    event.preventDefault()
    navigator.serviceWorker.ready
      .then((serviceWorkerRegistration) => {
        serviceWorkerRegistration.pushManager.getSubscription()
          .then(async function (subscription) {
            Rails.ajax({
              url: document.querySelector(".webpush-button").href,
              type: "GET",
              dataType: 'script',
              success: function (data) { },
              error: function (data) { }
            })
          });
      });
  }

  checkMandatoryFields(event) {
    if (event.target.classList.contains('email-field') || event.target.classList.contains('sms-field') || event.target.classList.contains('slack-field')) {
      var dbField = event.target.closest('.js-setting-group').querySelector('.database-field')
      if (event.target.closest('.js-setting-group').querySelectorAll('input.js-check-database:checked').length > 0) {
        dbField.checked = true
        dbField.disabled = true
      } else {
        dbField.disabled = false
      }
    }
  }
}
