import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  toggle(event) {
    event.preventDefault()
    let element_to_toggle = event.currentTarget.closest('.one-booking').querySelector('.quote-requests-details')
    let element_to_rotate = event.currentTarget.closest('.one-booking').querySelector('.caret svg')
    element_to_toggle.style.height = (element_to_toggle.style.height === 'auto' ? '0' : 'auto')
    element_to_rotate.style.transform = (element_to_toggle.style.height === 'auto' ? 'rotate(90deg)' : 'rotate(0deg)')
  }
}
