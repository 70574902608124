import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect() {
    const badges = document.querySelectorAll('.badge')

    if (badges) {
      badges.forEach((badge) => {
        var count = badge.closest('.dropdown').querySelectorAll('input:checked').length
        badge.innerHTML = count > 0 ? count : ''
      })
    }
    const checkbox = document.querySelector('#toggleArchivedEmployees');
    if (checkbox) {
      checkbox.addEventListener('change', this.submitFormWithTurbo.bind(this));
    }
  }

  submitFormWithTurbo(event) {
    const form = event.target.form;
    if (form) {
      const formData = new FormData(form);
      const formValues = Object.fromEntries(formData.entries());

      // Save the formValues to the cookie
      document.cookie = `onboarding_filters=${JSON.stringify(formValues)}; path=/; max-age=86400`; // Expires in a day

      Turbo.navigator.submitForm(form);
    }

    if (event.target.closest('.dropdown-menu')) {
      var count = event.target.closest('.dropdown-menu').querySelectorAll('input:checked').length
      event.target.closest('.dropdown').querySelector('.badge').innerHTML = count > 0 ? count : ''
    }
  }
}
