import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "scrollIndicatorBar", "scrollIndicatorProgress", "tasksContainer"]

  connect() {
    this.checkDisplayConditions();
  }

  scroll() {
    const tasksContainerFullWidth = this.tasksContainerTarget.scrollWidth;
    const scrollProgress = ((this.tasksContainerTarget.scrollLeft * 100) / tasksContainerFullWidth);
    this.scrollIndicatorProgressTarget.style.marginLeft = `${scrollProgress}%`;
  }

  checkDisplayConditions() {
    const windowWidth = window.innerWidth;
    const tasksLength = Number(this.scrollIndicatorBarTarget.dataset.tasksLength);

    if (windowWidth >= 1400 && tasksLength > 4) {
      this.scrollIndicatorBarTarget.style.display = 'block';
      this.scrollIndicatorProgressTarget.style.width = `${100 / (tasksLength / 4)}%`;
    } else if ((windowWidth >= 768 && windowWidth < 992) && tasksLength > 3) {
      this.scrollIndicatorBarTarget.style.display = 'block';
      this.scrollIndicatorProgressTarget.style.width = `${100 / (tasksLength / 3)}%`;
    } else if (((windowWidth >= 1200 && windowWidth < 1400) || (windowWidth >= 576 && windowWidth < 768) ) && tasksLength > 2) {
      this.scrollIndicatorBarTarget.style.display = 'block';
      this.scrollIndicatorProgressTarget.style.width = `${100 / (tasksLength / 2)}%`;
    } else if (((windowWidth >= 992 && windowWidth < 1200) || windowWidth < 576) && tasksLength > 1){
      this.scrollIndicatorBarTarget.style.display = 'block';
      this.scrollIndicatorProgressTarget.style.width = `${100 / tasksLength}%`;
    } else {
      this.scrollIndicatorBarTarget.style.display = 'none';
    }
  }
}
