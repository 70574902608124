export default class FLexframe {

  static get DEFAULT_PLACEHOLDER() {
    return 'Paste only the returnUrl part of the iframe url';
  }

  constructor({ data, config, api, readOnly }) {
    this.api = api;
    this.readOnly = readOnly;

    this._CSS = {
      block: this.api.styles.block,
      wrapper: 'ce-flexframe',
    };

    this._placeholder = config.placeholder ? config.placeholder : 'Paste only the returnUrl part of the iframe url';
    this._data = {};
    this._element = null;
    this._preserveBlank = config.preserveBlank !== undefined ? config.preserveBlank : false;

    this.data = data;
  }

  onKeyUp(e) {
    if (e.code !== 'Backspace' && e.code !== 'Delete') {
      return;
    }

    const { textContent } = this._element;

    if (textContent === '') {
      this._element.innerHTML = '';
    }
  }

  drawView() {
    const div = document.createElement('DIV');

    div.classList.add(this._CSS.wrapper, this._CSS.block);
    div.contentEditable = false;
    div.dataset.placeholder = this.api.i18n.t(this._placeholder);

    if (this._data.text) {
      div.innerHTML = this._data.text;
    }

    if (!this.readOnly) {
      div.contentEditable = true;
      div.addEventListener('keyup', this.onKeyUp);
    }

    return div;
  }

  render() {
    this._element = this.drawView();

    return this._element;
  }

  validate(savedData) {
    if (savedData.text.trim() === '' && !this._preserveBlank) {
      return false;
    }

    return true;
  }

  save(toolsContent) {
    return {
      text: toolsContent.innerHTML,
    };
  }

  onPaste(event) {
    const data = {
      text: event.detail.data.innerHTML,
    };

    this.data = data;
  }

  static get conversionConfig() {
    return {
      export: 'text', // to convert Flexframe to other block, use 'text' property of saved data
      import: 'text', // to covert other block's exported string to Flexframe, fill 'text' property of tool data
    };
  }

  static get sanitize() {
    return {
      text: {
        br: true,
      },
    };
  }

  /**
   * Returns true to notify the core that read-only mode is supported
   *
   * @returns {boolean}
   */
  static get isReadOnlySupported() {
    return true;
  }

  /**
   * Get current Tools`s data
   *
   * @returns {FlexframeData} Current data
   * @private
   */
  get data() {
    if (this._element !== null) {
      const text = this._element.innerHTML;

      this._data.text = text;
    }

    return this._data;
  }

  /**
   * Store data in plugin:
   * - at the this._data property
   * - at the HTML
   *
   * @param {FlexframeData} data — data to set
   * @private
   */
  set data(data) {
    this._data = data || {};

    if (this._element !== null) {
      this.hydrate();
    }
  }

  /**
   * Fill tool's view with data
   */
  hydrate(){
    window.requestAnimationFrame(() => {
      this._element.innerHTML = this._data.text || '';
    });
  }

  /**
   * Used by Editor paste handling API.
   * Provides configuration to handle P tags.
   *
   * @returns {{tags: string[]}}
   */
  static get pasteConfig() {
    return {
      tags: [ 'P' ],
    };
  }

  /**
   * Icon and title for displaying at the Toolbox
   *
   * @returns {{icon: string, title: string}}
   */
  static get toolbox() {
    return {
      icon: null,
      title: 'Flexframe',
    };
  }
}
