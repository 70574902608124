import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "step"]

  connect() {
    this.scrollToCurrentStep();
  }

  scrollToCurrentStep() {
    const viewportWidth = window.innerWidth;

    const container = this.element

    const currentStepIndex = this.stepTargets.findIndex(
      (target) => target.classList.contains('current-step')
    );

    let sections;
    if (viewportWidth >= 1400 || (viewportWidth >= 768 && viewportWidth < 992)) sections = 5;
    else if (viewportWidth >= 576 && viewportWidth < 768) sections = 4;
    else if (viewportWidth >= 992 && viewportWidth < 1400) sections = 3;
    else sections = 2;

    const pastStepsDisplayed = (sections === 2 || sections === 3) ? 1 : 2;

    const leftScroll = ((currentStepIndex - pastStepsDisplayed) * (container.clientWidth / sections));

    if (container) container.scroll(leftScroll, 0);
  }
}
