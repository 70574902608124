import { Sortable, Plugins } from '@shopify/draggable'
import Rails from '@rails/ujs'
import { Controller } from '@hotwired/stimulus'
import CableReady from 'cable_ready'
import consumer from "../channels/consumer"

export default class extends Controller {
  static targets = ["list"]

  connect() {
    const tasks_list_id = this.data.get("id")
    this.tasksChannel = consumer.subscriptions.create({ channel: "TasksChannel", id: tasks_list_id }, {
      received(data) {
        if (data.cableReady) CableReady.perform(data.operations)
      }
    });

    const containers = this.listTarget;
    this.sortable = new Sortable(containers, {
      draggable: '.is-draggable',
      delay: 200,
      distance: 50,
      mirror: {
        constrainDimensions: true,
      },
      plugins: [Plugins.ResizeMirror],
    })

    this.sortable.on('sortable:stop', (evt) => {
      let positionField = evt.data.dragEvent.source.querySelector('.position')
      let allPositionFields = evt.data.dragEvent.source.closest('.tasks').querySelectorAll('.is-draggable:not(.draggable-mirror):not(.draggable--original) .position')
      Array.prototype.forEach.call(allPositionFields, function (el, i) {
        el.value = i + 1
      });
      Rails.fire(positionField, 'change')
    })
  }

  disconnect() {
    if (this.sortable) {
      this.sortable.destroy();
    }
    this.tasksChannel.unsubscribe();
  }

  toggleDone(event) {
    Rails.fire(event.currentTarget.closest('form'), 'submit')
  }

  addTask() {
    this.listTarget.querySelector('.nested-fields:nth-last-of-type(2) input[name*="title"]').focus()
  }

  editTask(event) {
    Rails.fire(event.currentTarget.closest('form'), 'submit')
  }

  removeTask(event) {
    event.preventDefault();
    let taskEmelent = event.currentTarget.closest('.nested-fields');
    taskEmelent.querySelector('input[name*="_destroy"]').value = 1;
    this.listTarget.closest('form').requestSubmit();
  }

  editTitle(event) {
    Rails.fire(event.currentTarget.closest('form'), 'submit')
  }
}
