import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  showMessageForm(event) {
    if (event.target.checked) {
      document.querySelector('.poll-notification-wrapper').removeClass('d-none')
    } else {
      document.querySelector('.poll-notification-wrapper').addClass('d-none')
    }
  }
}
