import { Controller } from '@hotwired/stimulus'
import { useClickOutside } from 'stimulus-use'

export default class extends Controller {
  static targets = ['link']

  initialize() {
    document.querySelectorAll('#sideloader .trix-content a').forEach(link => {
      link.setAttribute('target', '_blank')
    })
    document.querySelector('#sideloader').addEventListener('click', (event) => {
      if (event.target.closest('.trix-content') == null) return true
      event.target.setAttribute('target', '_blank')
    })
  }

  connect() {
    window.SideloaderControllerInstance = this;

    useClickOutside(this)

    if (document.getElementById(window.location.hash.replace('-', '_').replace('#', ''))) {
      var evt = new MouseEvent("click", {
        view: window,
        bubbles: true,
        cancelable: true,
      });
      document.getElementById(window.location.hash.replace('-', '_').replace('#', '')).firstElementChild.dispatchEvent(evt);
    }
  }

  handleLocationChange(event) {
    if (this.data.get('modelOpened') == 'true' && this.hasLinkTarget) {
      event.preventDefault()
      this.data.set('modelOpened', false)
    }
  }

  openSideLoader() {
    if (document.body.classList.contains('sideloader-open')) {
      return true
    }

    document.getElementById('sideloader').innerHTML = '<div class="d-flex justify-content-center" style="height: 100vh;"><div class="spinner-border text-color-primary align-self-center" role="status"><span class="visually-hidden">Chargement...</span></div></div>'
    document.getElementById('sideloader').src = ''
    document.getElementById('sideloader-wrapper').classList.add('open')
    document.getElementById('sideloader-backdrop').classList.add('show')
    document.body.classList.add('sideloader-open')

    if (this.hasLinkTarget) {
      this.storeOriginalUrl();
    }
  }

  formSubmitted(event) {
    if (event.detail.success) {
      this.closeSideLoader(event)
    }
  }

  storeOriginalUrl() {
    if (this.hasLinkTarget) {
      this.data.set('originalUrl', window.location.href);
      this.data.set('modelOpened', true);
    }
  }

  navigateBack() {
    if (this.hasLinkTarget) {
      const originalUrl = this.data.get('originalUrl');
      if (originalUrl) {
        Turbo.clearCache();
        history.replaceState({}, '', originalUrl);
      }
    }
  }

  closeSideLoader(event) {
    if (!document.body.classList.contains('sideloader-open')) return

    document.getElementById('sideloader-wrapper').classList.remove('open')
    document.getElementById('sideloader-backdrop').classList.remove('show')
    document.body.classList.remove('sideloader-open')

    this.navigateBack();
  }
}
