import { Controller } from "@hotwired/stimulus"
import Swal from 'sweetalert2'

export default class extends Controller {
  static values = {
    title: String,
    html: String,
    icon: String
  }

  connect() {
    const bodyId = document.getElementById('kt_body')
    const position = bodyId ? 'top-end' : 'top-start'
    
    this.toast = Swal.mixin({
      toast: true,
      position: position,
      iconColor: 'white',
      customClass: {
        popup: 'colored-toast'
      },
      showConfirmButton: false,
      timer: 2500,
      timerProgressBar: true
    }).fire({
      icon: this.iconValue,
      title: this.titleValue,
      html: this.htmlValue
    })
  }
}
