import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["inputMain", "inputSecondary"]

  sync(event) {
    event.preventDefault()
    this.inputSecondaryTarget.value = this.inputMainTarget.value
  }
}
