import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['relativeFields', 'customDateField', 'startDate', 'syncEventDate', 'probationPeriodDate']

  connect() {
    this.toggleFields({ target: { value: this.data.get("dateChoice") } });
  }

  toggleFields(event) {
    const dateChoice = event.target.value;
    const relativeFields = this.relativeFieldsTarget;
    const customDateField = this.customDateFieldTarget;

    if (dateChoice === "onboarding_start_date" || dateChoice === "onboarding_sync_event_date" || dateChoice === "onboarding_probation_period_end_date") {
      relativeFields.classList.remove("d-none");
      customDateField.classList.add("d-none");
    } else if (dateChoice === "custom_date") {
      relativeFields.classList.add("d-none");
      customDateField.classList.remove("d-none");
    }

    if (dateChoice === "") {
      relativeFields.classList.add("d-none");
      customDateField.classList.add("d-none");
    }
  }
}
