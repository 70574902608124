import Rails from '@rails/ujs'
import { Controller } from '@hotwired/stimulus'
import { FetchRequest } from '@rails/request.js'

export default class extends Controller {
  static targets = ["results"]

  search(event) {
    var search_string = event.target.value
    Rails.ajax({
      type: "get",
      url: event.target.dataset.href + '?q=' + search_string,
      dataType: 'script',
      success: function (data) { },
      error: function (data) { }
    })
  }

  async searchInCharge(event) {
    var search_string = event.target.value
    const request = new FetchRequest('get', (event.target.dataset.href + '?type=in-charge&card-id=' + event.target.dataset.cardId + '&q=' + search_string), { responseKind: 'turbo-stream' })
    const response = await request.perform()


    // Rails.ajax({
    //   type: "get",
    //   url: event.target.dataset.href + '?type=in-charge&card-id=' + event.target.dataset.cardId + '&q=' + search_string,
    //   dataType: 'turbo_stream',
    //   success: function (data) { },
    //   error: function (data) { }
    // })
  }
}
