import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["seeMoreContent"];

  slideUp() {
    this.seeMoreContentTarget.style.display = 'grid';
    this.seeMoreContentTarget.style.position = 'absolute';
    this.seeMoreContentTarget.classList.add('animate-slide-up')
  }

  slideDown() {
    this.seeMoreContentTarget.classList.remove('animate-slide-up');
    this.seeMoreContentTarget.classList.add('animate-slide-down')
    setTimeout(() => {
      this.seeMoreContentTarget.style.display = 'none';
      this.seeMoreContentTarget.style.position = 'static';
      this.seeMoreContentTarget.classList.remove('animate-slide-down');
    }, 500);
  }
}
