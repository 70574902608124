import Rails from '@rails/ujs'
import { Controller } from "@hotwired/stimulus"
import { Calendar } from '@fullcalendar/core'
import dayGridPlugin from '@fullcalendar/daygrid'
import listPlugin from '@fullcalendar/list'
import bootstrapPlugin from '@fullcalendar/bootstrap5';
import { auto } from '@popperjs/core'

export default class extends Controller {
  static targets = ["element"]

  connect() {
    let calendar = new Calendar(this.elementTarget, {
      plugins: [dayGridPlugin, listPlugin, bootstrapPlugin, bootstrapPlugin],
      themeSystem: 'bootstrap',
      header: {
        left: 'prevCustomButton, nextCustomButton, today',
        center: 'title',
        right: 'dayGridWeek, dayGridMonth, list'
      },
      defaultView: 'listWeek',
      events: this.elementTarget.dataset.url,
      timezone: 'Europe/Paris',
      locale: 'fr',
      // contentHeight: 'auto',
      aspectRation: 1.1,
      fixedWeekCount: false,
      minTime: "09:00:00",
      maxTime: "20:00:00",
      customButtons: {
        prevCustomButton: {
          text: '<',
          click: function () {
            calendar.prev()
          }
        },
        nextCustomButton: {
          text: '>',
          click: function () {
            calendar.next()
          }
        }
      },
      noEventsMessage: 'Aucune prestation',
      buttonText: {
        today: 'aujourd\'hui',
        month: 'mois',
        week: 'semaine',
        day: 'jour',
        list: 'liste'
      },
      eventClick: function (eventClickInfo) {
        Rails.ajax({
          url: document.querySelector('#calendar').dataset.url + "/" + eventClickInfo.event.id,
          type: "GET",
          data: { id: event.id },
          dataType: 'script',
          success: function (data) { },
          error: function (data) { }
        })
      },
    })
    calendar.render()
  }
}
