import { Controller } from '@hotwired/stimulus'
import * as microsoftTeams from '@microsoft/teams-js'

export default class extends Controller {
  connect() {
    microsoftTeams.initialize()
  }

  authenticate(event) {
    event.preventDefault()
    microsoftTeams.authentication.authenticate({
      url: event.target.href,
      width: 600,
      height: 535,
      successCallback: function (result) {
        let data = localStorage.getItem(result);
        localStorage.removeItem(result);
        let tokenResult = JSON.parse(data);
        window.location.assign(tokenResult.configureUrl)
      },
      failureCallback: function (reason) {
        console.log(reason)
      }
    })
  }

  configure() {
    microsoftTeams.settings.setValidityState(true)
    microsoftTeams.settings.registerOnSaveHandler((saveEvent) => {
      microsoftTeams.settings.setSettings({
        websiteUrl: document.getElementById('test-form').dataset.url,
        contentUrl: document.getElementById('test-form').dataset.url + '?in_teams=1&teams_entity_id=group-tickets&' + new URLSearchParams(new FormData(document.querySelectorAll('form')[0])).toString(),
        entityId: "formi-tickets",
        suggestedDisplayName: "Tickets Formi",
        categoryIds: JSON.stringify(Array.from(document.getElementById('formi-categories').selectedOptions).map(el => el.value))
      })
      saveEvent.notifySuccess()
    })
  }
}
