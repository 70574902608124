import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.modal = new bootstrap.Modal(this.element)
  }
  hideModal() {
    console.log('hideModal')
    this.modal.hide()
  }

  // hide modal on successful form submission
  // action: "turbo:submit-end->turbo-modal#submitEnd"
  submitEnd(event) {
    console.log('submitEnd')
    if (event.detail.success) {
      this.hideModal()
    }
  }
}
