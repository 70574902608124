import { Controller } from '@hotwired/stimulus'
import CableReady from 'cable_ready'
import consumer from "../channels/consumer"

export default class extends Controller {
  connect() {
    const likeable_type = this.data.get("likeableType")
    const likeable_id = this.data.get("likeableId")

    this.likesChannel = consumer.subscriptions.create({ channel: "LikesChannel", likeable_type: likeable_type, likeable_id: likeable_id }, {
      received(data) {
        if (data.cableReady) CableReady.perform(data.operations)
      }
    });
  }

  disconnect() {
    this.likesChannel.unsubscribe()
  }

  like() {
    // console.log('like')
  }

  showList() {
    event.currentTarget.closest('.likes-container').querySelector('.likes-list').classList.toggle('d-none')
  }
}
