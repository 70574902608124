import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  changeTrainingType(event) {
    let trainingType = event.target.value

    event.target.closest('form').querySelectorAll('.training-special-fields').forEach(function (wrapper) {
      wrapper.style.display = 'none'
    })
    event.target.closest('form').querySelectorAll('.training-special-fields input').forEach(function (input) {
      input.disabled = true
    })

    event.target.closest('form').querySelector('.training-special-fields.' + trainingType + '-fields').style.display = 'block'
    event.target.closest('form').querySelector('.training-special-fields.' + trainingType + '-fields').querySelectorAll('input').forEach(function (input) {
      input.disabled = false
    })
  }
}
