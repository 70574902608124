import Rails from '@rails/ujs'
import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['multisiteParam']
  connect() {
    const multisiteButton = this.element.querySelector('.dropdown-item[data-multisite="true"]')
    console.log(multisiteButton)
    const currentCompanyButton = this.element.querySelector('.dropdown-item[data-multisite="false"]')

    multisiteButton.addEventListener('click', (event) => {
      this.multisiteParamTarget.value = 'true'
      this.updateTabContent()
    })

    currentCompanyButton.addEventListener('click', (event) => {
      this.multisiteParamTarget.value = 'false'
      this.updateTabContent()
    })
  }

  updateTabContent() {
    Turbo.visit(window.location.href, { action: 'replace', target: 'reporting_frame' })
  }

  change(event) {
    var formData = new FormData(this.element)
    const queryString = new URLSearchParams(formData).toString()
    Rails.ajax({
      type: "get",
      url: event.currentTarget.action + '?' + queryString,
      dataType: 'script',
      success: function (data) { },
      error: function (data) { }
    })

    var count = event.target.closest('.dropdown-menu').querySelectorAll('input:checked').length
    event.target.closest('.dropdown').querySelector('.badge').innerHTML = count > 0 ? count : ''
  }
}
