import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["item"];

  isDropdownOpen = true;
  currentSectionIndex = 0;

  initialize() {
    this.toggleDropdown();
    document.addEventListener("click", this.handleDocumentClick.bind(this));
  }

  toggleDropdown() {
    this.isDropdownOpen = !this.isDropdownOpen;

    this.itemTargets.forEach((item) => {
      if (this.isDropdownOpen) item.style.display =  'block';
      else item.style.display = 'none';
    });
  }

  handleDocumentClick(event) {
    if (!this.element.contains(event.target)) {
      this.isDropdownOpen = false;

      this.itemTargets.forEach((item) => {
        item.style.display = 'none';
      });
    }
  }

  disconnect() {
    document.removeEventListener("click", this.handleDocumentClick.bind(this));
  }
}
