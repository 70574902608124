import { Controller } from "@hotwired/stimulus"
import { GridStack } from 'gridstack'
import { FetchRequest } from '@rails/request.js'
import CableReady from 'cable_ready'
import consumer from "../channels/consumer"

export default class extends Controller {
  static values = { url: String, data: Array, dashboardId: Number, editable: Boolean }
  static targets = ['makeWidget']

  connect() {
    this.grid = GridStack.init({
      handle: '.gridstack-drag-handle',
      cellHeight: '5rem',
      column: 12,
      minRow: 1,
      staticGrid: !this.editableValue,
      resizable: { autoHide: true, handles: 'ne,se,nw,sw' },
    })
    this.dashbaordsChannel = consumer.subscriptions.create({ channel: "DashboardsChannel", id: this.dashboardIdValue }, {
      received(data) {
        if (data.cableReady) CableReady.perform(data.operations)
      }
    });
  }

  async update(event) {
    if (!this.grid) return
    let gsdata = this.grid.save(false)
    gsdata.forEach(item => { delete (item['content']) })

    const request = new FetchRequest('patch', this.urlValue, { body: { dashboard: { gridstack_data: JSON.stringify(gsdata) } }, responseKind: 'json' })
    const response = await request.perform()
  }

  makeWidgetTargetConnected(element) {
    this.grid.makeWidget(element)
  }

  updateBlock(event) {
    this.grid.removeWidget(document.querySelector(event.detail.id), false)
    document.querySelector(event.detail.id).outerHTML = event.detail.html
    this.grid.makeWidget(document.querySelector(event.detail.id))
  }
}
