import { Controller } from "@hotwired/stimulus"
import { FetchRequest } from "@rails/request.js"

export default class extends Controller {
  static values = { active: Boolean, url: String }
  static targets = ["input"]

  open(event) {
    if (this.activeValue) {
      this.inputTarget.click()
    }
  }

  async upload(event) {
    event.preventDefault()

    let formData = new FormData()
    formData.append("employee[avatar]", this.inputTarget.files[0])
    const request = new FetchRequest('patch', this.urlValue, { body: formData, responseKind: "turbo-stream" })
    const response = await request.perform()
    // if (response.ok) {
    //   const body = await response.text
    //   // Do whatever do you want with the response body
    //   // You also are able to call `response.html` or `response.json`, be aware that if you call `response.json` and the response contentType isn't `application/json` there will be raised an error.
    // }
  }
}
