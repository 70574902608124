import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [ "toggle" ]

  connect() {
    this.toggleTarget.classList.add('active');
  }

  toggle() {
    this.toggleTargets.forEach(elem => {
      if (elem.classList.contains('active')) elem.classList.remove('active');
      else elem.classList.add('active');
    });
  }
}
