import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ["selector", "templateSelector", "documentSelector", "roleSelector"]

  connect() {
    this.changeDocType()
  }

  changeDocType() {
    this.element.closest('form').querySelectorAll('.duty-document-fields').forEach(function (wrapper) {
      wrapper.style.display = 'none'
    })
    this.element.closest('form').querySelectorAll('.duty-document-fields input').forEach(function (input) {
      input.disabled = true
    })

    this.element.closest('form').querySelector('.duty-document-fields.' + this.selectorTarget.value + '-fields').style.display = 'block'
    this.element.closest('form').querySelector('.duty-document-fields.' + this.selectorTarget.value + '-fields').querySelectorAll('input').forEach(function (input) {
      input.disabled = false
    })
  }

  selectTemplate(event) {
    console.log('selectTemplate');
    document.querySelector("input[name='document[template_id]']").value = this.templateSelectorTarget.value
    console.log(this.templateSelectorTarget.value);
  }

  selectDocument(event) {
    const documentId = this.documentSelectorTarget.value;
    console.log(documentId);
    const option = this.documentSelectorTarget.querySelector(`option[value="${documentId}"]`);
    console.log(option);

    if (option) {
      const templateId = option.dataset.templateId;
      console.log(templateId);

      if (templateId) {
        const turboFrame = document.getElementById('docusign-template-roles');
        if (turboFrame) {
          console.log(turboFrame);
          turboFrame.src = `/documents/${documentId}/document_roles`;
          console.log(turboFrame.src);
          this.showRolesDropdown();
        }
      } else {
        this.hideRolesDropdown();
      }
    }
  }

  showRolesDropdown() {
    const rolesDropdown = document.querySelector('.role-names-dropdown');
    if (rolesDropdown) {
      rolesDropdown.classList.remove('d-none');
    }
  }

  hideRolesDropdown() {
    const rolesDropdown = document.querySelector('.role-names-dropdown');
    if (rolesDropdown) {
      rolesDropdown.classList.add('d-none');
    }
  }


  selectRole(event) {
    console.log(this.roleSelectorTarget)
    const roleName = this.roleSelectorTarget.value
    console.log(roleName);
  }
}
