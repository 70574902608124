import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = { latitude: Number, longitude: Number }
  static targets = ["div"]

  connect() {
    this.initMap()
  }

  initMap() {
    const position = { lat: this.latitudeValue, lng: this.longitudeValue }

    let map = new google.maps.Map(this.divTarget, {
      center: position,
      zoom: 15,
      mapId: '79177f37d7eea8b6',
      mapTypeControl: false
    });

    const marker = new google.maps.Marker({
      position: position,
      map: map,
    });
  }
}
