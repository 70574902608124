// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"
import "./turbo_streams"
import './channels'
import "./controllers"
import * as bootstrap from "bootstrap"
import "trix"
import "@rails/actiontext"
import * as ActiveStorage from "@rails/activestorage"
import "@Belibaste/cocoon-vanilla-js"
import "chartkick/chart.js"
import "./config/stimulus_reflex"

ActiveStorage.start()

window.bootstrap = bootstrap

window.initMap = function (...args) {
  const event = document.createEvent("Events")
  event.initEvent("google-maps-callback", true, true)
  event.args = args
  window.dispatchEvent(event)
}

window.wobeeGetCookie = function (name) {
  var v = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');
  return v ? v[2] : null;
}

window.wobeeSetCookie = function (name, value, days) {
  var d = new Date;
  d.setTime(d.getTime() + 24 * 60 * 60 * 1000 * days);
  document.cookie = name + "=" + value + ";path=/;expires=" + d.toGMTString();
}

// if (window.wobeeGetCookie('hasSeenBandalModal') == null) {
//   new window.bootstrap.Modal('#bandal-modal', {
//     keyboard: false
//   }).show()
//   window.wobeeSetCookie('hasSeenBandalModal', 'true', 1)
// }

