import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  showTab(event) {
    event.preventDefault()
    const tabIndex = event.currentTarget.dataset.category

    document.querySelector('.tab-header.active').classList.remove('active')
    event.currentTarget.parentElement.classList.add('active')

    document.querySelectorAll('.one-resource-category').forEach(element => {
      element.classList.remove('active')
    })
    document.getElementById(tabIndex).classList.add('active')
  }
}
