import { Controller } from "@hotwired/stimulus"
import { useMeta } from 'stimulus-use'

export default class extends Controller {
  static values = { doableBy: Array, lockIcon: String }
  static metaNames = ['userId']

  connect() {
    // useMeta(this)

    // if (!this.doableByValue.includes(this.userIdMeta)) {
    //   this.element.querySelector('.one-duty').classList.add('not-doable')
    //   this.element.querySelector('.svg-badge-container').innerHTML = this.lockIconValue
    // }
  }
}
