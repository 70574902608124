import { Sortable, Plugins } from "@shopify/draggable"
import Rails from "@rails/ujs"
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    // Open duty on load if params in url
    var parsedUrl = new URL(window.location.href)
    if (parsedUrl.searchParams.get("duty") != null || parsedUrl.searchParams.get("duty") != '') {
      var id = parsedUrl.searchParams.get("duty")
      var element = document.querySelectorAll('.one-duty-wrapper a[data-id="' + id + '"]')[0]
      if (element) {
        element.click()
      }
    }
  }

  showTimePeriod(event) {
    event.preventDefault()

    if (event.currentTarget.parentElement.classList.contains('active')) {
      event.currentTarget.parentElement.classList.remove('active')
    } else {
      document.querySelectorAll('.duties-column .time-period').forEach(element => {
        element.classList.remove('active')
      })
      event.currentTarget.parentElement.classList.add('active')
    }
  }
}
