import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    // Open post on load if params in url
    var parsedUrl = new URL(window.location.href)
    var hash = parsedUrl.hash.substring(1);
    if (hash) {
      var element = document.getElementById(hash)
      if (element) {
        //this.showModal({ currentTarget: element, preventDefault: function () { return null; } })
        element.querySelector('a').click()
      }
    }
  }
}

