import { Controller } from '@hotwired/stimulus'
import CableReady from 'cable_ready'
import consumer from "../channels/consumer"

export default class extends Controller {
  connect() {
    const commentable_type = this.data.get("commentableType")
    const commentable_id = this.data.get("commentableId")
    this.commentsChannel = consumer.subscriptions.create({ channel: "CommentsChannel", commentable_type: commentable_type, commentable_id: commentable_id }, {
      received(data) {
        if (data.cableReady) {
          CableReady.perform(data.operations)
          document.querySelectorAll('trix-editor').forEach(function (el) {
            el.value = ''
          })
        }
      }
    });
  }

  disconnect() {
    this.commentsChannel.unsubscribe()
  }
}
