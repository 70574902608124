import { FetchRequest } from '@rails/request.js'

export default class ServerBackedOembed {
  static get toolbox() {
    return {
      title: 'Oembed: importer un lien Youtube, Vimeo, Twitter, Instagram',
      icon: '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-camera-video" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M0 5a2 2 0 0 1 2-2h7.5a2 2 0 0 1 1.983 1.738l3.11-1.382A1 1 0 0 1 16 4.269v7.462a1 1 0 0 1-1.406.913l-3.111-1.382A2 2 0 0 1 9.5 13H2a2 2 0 0 1-2-2V5zm11.5 5.175 3.5 1.556V4.269l-3.5 1.556v4.35zM2 4a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1h7.5a1 1 0 0 0 1-1V5a1 1 0 0 0-1-1H2z"/></svg>'
    };
  }

  constructor({ data }) {
    this.data = data;
    this.wrapper = undefined;
    this.oembedHtmlWrapper = undefined;
  }

  render() {
    this.wrapper = document.createElement('div');
    this.oembedHtmlWrapper = document.createElement('div');
    const input = document.createElement('input');

    if (this.data && this.data.meta && this.data.meta.oembed_html) {
      this._showOembedHtml(this.data.meta.oembed_html);
    }

    this.wrapper.classList.add('server-backed-oembed');
    this.oembedHtmlWrapper.classList.add('oembed-html-wrapper')
    this.wrapper.appendChild(input);
    this.wrapper.appendChild(this.oembedHtmlWrapper);

    input.placeholder = 'Paste an URL...';
    input.classList.add('cdx-input')
    input.value = this.data && this.data.meta && this.data.meta.url ? this.data.meta.url : '';

    input.addEventListener('paste', (event) => {
      this._getOembed(event.clipboardData.getData('text'));
    });

    return this.wrapper;
  }

  async _getOembed(url) {
    this.oembedHtmlWrapper.classList.add('cdx-loader')
    const request = new FetchRequest('post', '/editor-blocks/oembed-backend', { body: JSON.stringify({ url: url }) })
    const response = await request.perform()
    if (response.ok) {
      const body = await response.json
      this.oembedHtmlWrapper.classList.remove('cdx-loader')
      this._showOembedHtml(body.meta.oembed_html)
      this.data = body
    }
  }

  _showOembedHtml(html) {
    this._clearPreview()
    this.oembedHtmlWrapper.insertAdjacentHTML('beforeend', html)

    Array.from(this.oembedHtmlWrapper.querySelectorAll("script")).forEach(oldScript => {
      const newScript = document.createElement("script");
      Array.from(oldScript.attributes)
        .forEach(attr => newScript.setAttribute(attr.name, attr.value));
      newScript.appendChild(document.createTextNode(oldScript.innerHTML));
      oldScript.parentNode.replaceChild(newScript, oldScript);
    });
  }

  _clearPreview() {
    while (this.oembedHtmlWrapper.firstChild) {
      this.oembedHtmlWrapper.removeChild(this.oembedHtmlWrapper.lastChild);
    }
  }

  save(blockContent) {
    return this.data
  }

  validate(savedData) {
    if (!savedData.meta.oembed_html.trim()) {
      return false;
    }

    return true;
  }
}
