import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["input"]

  connect() {
    console.log('Connect quantity field')
  }

  add(event) {
    event.preventDefault()
    let currentValue = parseInt(this.inputTarget.value)
    if (isNaN(currentValue)) {
      this.inputTarget.value = 1
    } else {
      this.inputTarget.value = ++currentValue
    }
  }

  substract(event) {
    event.preventDefault()
    let currentValue = parseInt(this.inputTarget.value)
    if (isNaN(currentValue) || (currentValue - 1 < 0)) {
      this.inputTarget.value = 0
    } else {
      this.inputTarget.value = --currentValue
    }
  }
}
