import { Controller } from "@hotwired/stimulus"
import { Calendar } from '@fullcalendar/core'
import frLocale from '@fullcalendar/core/locales/fr';
import listPlugin from '@fullcalendar/list'
import timeGridPlugin from '@fullcalendar/timegrid'
import bootstrap5Plugin from '@fullcalendar/bootstrap5';
import dayGridPlugin from '@fullcalendar/daygrid';
import { info } from "sass";

export default class extends Controller {
  static targets = ["placeholder"]
  static values = {
    startDate: String,
    url: String,
    view: String
  }

  connect() {
    this.calendar = new Calendar(this.placeholderTarget, {
      plugins: [listPlugin, timeGridPlugin, bootstrap5Plugin, dayGridPlugin],
      initialView: this.viewValue,
      // event triggered when view changes and save view to a cookie
      datesSet: (info) => {
        fetch('/onboardings/update_calendar_view', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
          },
          body: JSON.stringify({ view: info.view.type })
        })
      },
      initialDate: this.startDateValue,
      events: { id: 'onboardings', url: this.urlValue },
      dayMaxEvents: 2,
      dayMaxEventRows: 2,
      locales: [frLocale],
      themeSystem: 'bootstrap5',
      headerToolbar: {
        left: 'prev title next',
        center: '',
        end: 'dayGridMonth,listYear'
      },
      locale: document.documentElement.lang,
      fixedWeekCount: false,
      slotMinTime: "08:00:00",
      slotMaxTime: "20:00:00",
      weekends: false,
      allDaySlot: true,
      firstDay: 1,
      height: 'auto',
      dayHeaders: true,
      eventBackgroundColor: 'red',
      buttonIcons: { prev: 'arrow-left-square-fill', next: 'arrow-right-square-fill' },

      eventClick: function (info) {
        if (info.event.url) {
          window.location.href = info.event.url
        }
      },


    });
    this.calendar.render();
    window.calendar = this.calendar

    // this doesn't refer to original object because it's called from the event listener so we need to bind it
    // bind creates a new version of the method where this is always the current instance of the controller
    this.boundHandleTurboSubmitEnd = this.handleTurboSubmitEnd.bind(this);
    this.boundHandleTurboFrameLoad = this.handleTurboFrameLoad.bind(this);

    document.addEventListener("turbo:submit-end", this.boundHandleTurboSubmitEnd);
    document.addEventListener("turbo:frame-load", this.boundHandleTurboFrameLoad);
  }

  disconnect() {
    // remove event listeners when turbo event is fired, the form is submitted and the content is loaded
    document.removeEventListener("turbo:submit-end", this.boundHandleTurboSubmitEnd);
    document.removeEventListener("turbo:frame-load", this.boundHandleTurboFrameLoad);
  }

  updateEventSourceURL() {
    // gets the base URL, adds the form data as query params and appends it to the event source URL
    let baseURL = this.urlValue;

    let queryParams = new URLSearchParams(new FormData(document.querySelector('.filters'))).toString();

    let updatedURL = `${baseURL}?${queryParams}`;

    this.calendar.getEventSourceById('onboardings').setProp('url', updatedURL);
  }


  handleTurboSubmitEnd(event) {
    // Callback function called when turbo event is fired - indicates that the form is submitted
    if (event.target.getAttribute("data-turbo-frame") === "onboardings") {
      this.updateEventSourceURL();
      this.reloadOnboardings();
      this.calendar.changeView(this.viewValue);
    }
  }

  handleTurboFrameLoad(event) {
    // Callback function called when turbo event is fired - indicates that the content is loaded
    if (event.target.id === "onboardings") {
      this.updateEventSourceURL();
      this.reloadOnboardings();
      this.calendar.changeView(this.viewValue);
    }
  }

  reloadOnboardings() {
    this.calendar.refetchEvents()
  }
}
