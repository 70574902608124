import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect() {
    const myDropdown = document.querySelector('.dropdown.keep-open')
    myDropdown.addEventListener('hide.bs.dropdown', e => {
      if (e.clickEvent && e.clickEvent.target.closest('.dropdown') === e.currentTarget) {
        e.stopPropagation()
        e.preventDefault()
        return false
      }
    })
  }
}
