import Sortable from 'stimulus-sortable'

export default class extends Sortable {
  onUpdate(event) {
    console.log("Sortable onUpdate triggered")
    this.element.querySelectorAll('.nested-fields').forEach(function (element, index) {
      element.querySelector('input.position-field').value = index + 1
    })
  }
}
