import { Controller } from "@hotwired/stimulus"
import { FetchRequest } from '@rails/request.js'
import pDebounce from 'p-debounce';
import SlimSelect from '../../../node_modules/slim-select/dist/slimselect.es.js';

export default class extends Controller {
  static values = {
    url: String,
    addable: { type: Boolean, default: false }
  }

  connect() {
    this.cleanup()
    if(this.element.multiple) {
      // Add a placeholder option for multiple select
      const opt1 = document.createElement("option")
      opt1.dataset.placeholder = true
      this.element.options.add(opt1)
    }
    const options = {
      select: this.element,
      settings: {
        closeOnSelect: !this.element.multiple,
        placeholder: this.placeholder,
        searchHighlight: true,
        allowDeselect: this.element.multiple
      },
      events: {}
    }

    options.events.addable = this.addable

    if(this.hasUrlValue) {
      const _search = async (search, currentData) => {
        return new Promise(async (resolve, reject) => {
          if (search.length < 2) {
            return reject('Search must be at least 2 characters')
          }
          const query = new URLSearchParams()
          query.append('search', search)
          const request = new FetchRequest('get', this.urlValue, {
            contentType: "application/json",
            responseKind: "json",
            query: query
          })
          const response = await request.perform()
          if (response.ok) {
            const data = await response.text
            let parsedData = JSON.parse(data)
            parsedData = parsedData['employees']
            return resolve(parsedData)
          }
          return reject('Request failed')
        })
      }
      options.events.search = pDebounce(_search, 250)
    }
    this.slimSelect = new SlimSelect(options)
  }

  disconnect (event) {
    this.cleanup()
  }

  get placeholder() {
    return this.element.getAttribute('placeholder')
  }

  get addable() {
    if (this.addableValue) {
      return function (value) {
        return {
          text: value.toLowerCase(),
          value: value.toLowerCase(),
          selected: true
        }
      }
    }
    return;
  }

  cleanup(event) {
    if (!this.slimSelect) return
    this.slimSelect.destroy()
    delete this.slimSelect
  }
}
