import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  showRecurrentFields() {
    var checkedInput = event.currentTarget.closest('.btn-group-toggle').querySelector('input:checked')
    var elements = document.querySelectorAll('.recurrence-options')
    if (checkedInput.value === "false") {
      Array.prototype.forEach.call(elements, function (el, i) {
        el.classList.remove('d-block')
        el.classList.add('d-none')
      })
    } else {
      Array.prototype.forEach.call(elements, function (el, i) {
        el.classList.remove('d-none')
        el.classList.add('d-block')
      })
    }
  }
}
