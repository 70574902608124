import { Controller } from '@hotwired/stimulus'
import { FetchRequest } from '@rails/request.js'

export default class extends Controller {
  connect() {
    this.showLoader();
  }

  async resetDocument(event) {
    const confirmationMessage = event.currentTarget.dataset.confirmMessage;

    if (confirmationMessage && !window.confirm(confirmationMessage)) {
      event.preventDefault();
      return; // Exit the function if the user did not confirm
    }

    event.preventDefault();
    const request = new FetchRequest('delete', event.currentTarget.href, {responseKind: 'turbo-stream'});
    const response = await request.perform();

    if (response.ok) {
      const body = await response.text;
      // Handle the response as you need
    }
  }

  loadDocument(event) {
    if (event.target.id === 'docusign-agreement') {
      this.hideLoader();
    }
  }

  showLoader() {
    document.querySelector('.page-loader').style.display = 'flex';
  }

  hideLoader() {
    document.querySelector('.page-loader').style.display = 'none';
  }
}
