import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = {
    url: String,
    name: String,
    key: String,
    parentDiv: String
  }

  connect() {
    var adobeDCView = new AdobeDC.View({ clientId: this.keyValue, divId: this.element.id, parentDivId: this.parentDivValue })
    adobeDCView.previewFile({
      content: { location: { url: this.urlValue } },
      metaData: { fileName: this.nameValue }
    }, {
      embedMode: "IN_LINE",
    });
  }
}
