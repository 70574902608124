import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["currentSection", "section"];
  static values = { sections: Array}

  isDropdownOpen = false;
  currentSectionIndex = 0;

  connect() {
    document.addEventListener("click", this.handleDocumentClick.bind(this));
    if (window.innerWidth >= 576) this.sectionTargets[0].classList.add('active-section');
  }

  select() {
    const selectedIndex = event.currentTarget.dataset.itemIndex;

    if (window.innerWidth < 576) {
    const selectedSection = this.sectionsValue[selectedIndex];

    this.currentSectionIndex = selectedIndex;

    this.currentSectionTarget.innerHTML = `
      <span class="select-icon">${selectedSection.icon}</span>
      ${selectedSection.label}
    `;

    this.toggleDropdown();

    } else {
      this.sectionTargets.forEach((section, index) => {
        if (index == selectedIndex) section.classList.add('active-section');
        else section.classList.remove('active-section');
      })
    }
  }

  toggleDropdown() {
    this.isDropdownOpen = !this.isDropdownOpen;

    this.sectionTargets.forEach((section, index) => {
      if (this.isDropdownOpen) section.style.display = (index == this.currentSectionIndex) ? 'none' : 'block';
      else section.style.display = 'none';
    });
  }

  handleDocumentClick(event) {
    if (!this.element.contains(event.target)) {
      this.isDropdownOpen = false;

      if (window.innerWidth < 576) {
        this.sectionTargets.forEach((section) => {
          section.style.display = 'none';
        });
      }
    }
  }

  disconnect() {
    document.removeEventListener("click", this.handleDocumentClick.bind(this));
  }
}
