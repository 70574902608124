import Rails from "@rails/ujs"
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = { url: String }

  connect() {}

  updateRecord(event) {
    event.preventDefault()

    const attribute = event.target.dataset.attribute
    let formData = new FormData()
    formData.append("employee[" + attribute + "]", event.target.innerHTML)
    Rails.ajax({
      url: this.urlValue,
      type: "PATCH",
      data: formData,
      dataType: 'script',
      success: function (data) { },
      error: function (data) { }
    })
  }

  updateRecordFromSelect(event) {
    event.preventDefault()
    const attribute = event.target.dataset.attribute
    const collection = event.target.selectedOptions
    let selected = []
    for (let i = 0; i < collection.length; i++) {
      selected.push(collection[i].value)
    }
    let formData = new FormData()
    formData.append("employee[" + attribute + "]", selected)
    Rails.ajax({
      url: document.URL,
      type: "PATCH",
      data: formData,
      dataType: 'script',
      success: function (data) { },
      error: function (data) { }
    })
  }

  clearInput(event) {
    event.target.innerHTML = ''
  }
}
