import { Controller } from "@hotwired/stimulus"
import { Popover } from "bootstrap"

export default class extends Controller {
  static targets = ["element", "title", "content"]

  connect() {
    new Popover(this.elementTarget, {
      html: true,
      sanitize: false,
      trigger: 'hover',
      content: (element) => {
        return this.contentTarget.innerHTML
      },
      title: (element) => {
        return this.titleTarget.innerHTML
      }
    })
  }
}
