import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  showChatTab(event) {
    event.preventDefault()
    const tabIndex = event.currentTarget.dataset.tabindex
    document.querySelectorAll('.chats .one-chat, .chat-targets .one-target').forEach(element => {
      element.classList.remove('active')
    })
    document.querySelectorAll('.chats .one-chat[data-tabindex="' + tabIndex + '"], .chat-targets .one-target[data-tabindex="' + tabIndex + '"]').forEach(element => {
      element.classList.add('active')
    })
  }
}
