import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["button"]

  connect() {
    this.handleFrameLoad = this.handleFrameLoad.bind(this);
    document.addEventListener('turbo:frame-load', this.handleFrameLoad);
  }

  disconnect() {
    document.removeEventListener('turbo:frame-load', this.handleFrameLoad);
  }

  handleFrameLoad() {
    const breadcrumb = document.querySelector('.multisite-breadcrumb');

    const addBreadcrumbItem = (companyName, companyId) => {
      if (breadcrumb.querySelector(`button[data-company-id="${companyId}"]`)) return;

      if (breadcrumb.children.length > 0) {
        const spanSeparator = document.createElement('span');
        spanSeparator.textContent = ' > ';
        breadcrumb.appendChild(spanSeparator);
      }

      const buttonCompany = document.createElement('button');
      buttonCompany.textContent = companyName;
      buttonCompany.dataset.companyId = companyId;
      breadcrumb.appendChild(buttonCompany);

      buttonCompany.addEventListener('click', () => handleBreadcrumbClick(buttonCompany));
    };

    const handleMenuClick = (button) => {
      const parentLi = button.closest('li');
      const companyName = parentLi.dataset.companyName;
      const companyId = parentLi.dataset.companyId;

      addBreadcrumbItem(companyName, companyId);

      const grandparentUl = parentLi.closest('ul');
      const submenu = button.nextElementSibling;

      grandparentUl.classList.add('slide-out');
      document.querySelectorAll('.menu_transition').forEach(ul => ul.classList.remove('slide-in'));

      if (submenu) {
        if (submenu.classList.contains('slide-out')) {
          submenu.classList.remove('slide-out');
        }
        submenu.classList.add('slide-in');
      }

    };

    const handleBreadcrumbClick = (button) => {
      const companyId = button.dataset.companyId;
      const targetUl = document.querySelector(`ul#menu_transition-${companyId}`);

      if (!targetUl) {
        console.error(`Element with id="menu_transition-${companyId}" not found.`);
        return;
      }

      document.querySelectorAll('.menu_transition.slide-in').forEach(ul => {
        ul.classList.remove('slide-in');
        ul.classList.add('slide-out');
      });

      targetUl.classList.remove('slide-out');
      targetUl.classList.add('slide-in');
      targetUl.querySelectorAll('.menu_transition input[type="radio"]').forEach(radio => radio.checked = false);

      let nextSibling = button.nextElementSibling;
      while (nextSibling) {
        const toRemove = nextSibling;
        nextSibling = nextSibling.nextElementSibling;
        toRemove.remove();
      }

      if (breadcrumb.lastChild && breadcrumb.lastChild.tagName === 'SPAN') {
        breadcrumb.lastChild.remove();
      }
    };

    document.querySelectorAll('.toggle-button').forEach(button => {
      button.addEventListener('click', () => handleMenuClick(button));
    });

    document.querySelectorAll('.multisite-breadcrumb button').forEach(button => {
      button.addEventListener('click', () => handleBreadcrumbClick(button));
    });
  }
}
