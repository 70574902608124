import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ["fieldTypeSelector", "linkableModelSelector", "multipleCheckbox", "choicesList"]

  connect() {
    this.fieldTypeSelected()
  }

  fieldTypeSelected() {
    if (this.fieldTypeSelectorTarget.value == 'model_link') {
      this.multipleCheckboxTarget.disabled = true
      this.multipleCheckboxTarget.classList.add('d-none')
      this.choicesListTarget.disabled = true
      this.choicesListTarget.classList.add('d-none')
      this.linkableModelSelectorTarget.style.display = 'block'
      this.linkableModelSelectorTarget.querySelectorAll('select, input').forEach((element) => {
        element.disabled = false
      })
    } else if (this.fieldTypeSelectorTarget.value == 'select') {
      this.linkableModelSelectorTarget.style.display = 'none'
      this.linkableModelSelectorTarget.querySelectorAll('select, input').forEach((element) => {
        element.disabled = true
      })
      this.multipleCheckboxTarget.disabled = false
      this.multipleCheckboxTarget.classList.remove('d-none')
      this.choicesListTarget.disabled = false
      this.choicesListTarget.classList.remove('d-none')
    } else {
      this.linkableModelSelectorTarget.style.display = 'none'
      this.linkableModelSelectorTarget.querySelectorAll('select, input').forEach((element) => {
        element.disabled = true
      })
      this.multipleCheckboxTarget.disabled = false
      this.multipleCheckboxTarget.classList.add('d-none')
      this.choicesListTarget.disabled = false
      this.choicesListTarget.classList.add('d-none')
    }
  }
}
