import { Controller } from '@hotwired/stimulus'
import { FetchRequest } from '@rails/request.js'

export default class extends Controller {
  connect() {
    this.element.formiStokpileSelected = []
  }

  select(event) {
    event.preventDefault()
    let toggled = event.currentTarget.querySelector('.one-duty.card').classList.toggle('duty-done')
    if (toggled) {
      this.element.formiStokpileSelected.push(event.currentTarget.dataset.id)
    } else {
      this.element.formiStokpileSelected = this.element.formiStokpileSelected.filter(v => v !== event.currentTarget.dataset.id);
    }
    console.log(this.element.formiStokpileSelected)
  }

  async add(event) {
    event.preventDefault()
    const request = new FetchRequest('post', this.element.dataset.url, { body: JSON.stringify({ duties: this.element.formiStokpileSelected }) })
    const response = await request.perform()
    if (response.ok) {
      const body = await response.text
      if (response.ok) {
        alert('Actions ajoutées !')
        location.reload()
      } else {
        alert('Erreur')
      }
      // Do whatever do you want with the response body
      // You also are able to call `response.html` or `response.json`, be aware that if you call `response.json` and the response contentType isn't `application/json` there will be raised an error.
    }
  }
}
