import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.handleResize();
  }

  handleResize() {
    let mainContainer = this.element.querySelector(".main-container");
    let mainContent = this.element.querySelectorAll(".main");

    let sidebarContainer = this.element.querySelector(".sidebar-container");
    let sidebarContent = this.element.querySelectorAll(".sidebar");

    if (window.innerWidth >= 992) {
      mainContainer = document.createElement("div");
      mainContainer.classList.add("main-container", "col-lg-7", "col-xl-8", "col-xxl-9");

      sidebarContainer = document.createElement("div");
      sidebarContainer.classList.add("sidebar-container", "col-lg-5", "col-xl-4", "col-xxl-3");

      mainContent.forEach((mainDiv) => {
        mainDiv.classList.remove(...Array.from(mainDiv.classList).filter(cls => cls.startsWith('order-')));
        mainContainer.appendChild(mainDiv);
      });

      sidebarContent.forEach((sidebarDiv) => {
        sidebarDiv.classList.remove(...Array.from(sidebarDiv.classList).filter(cls => cls.startsWith('order-')));
        sidebarContainer.appendChild(sidebarDiv);
      });

      this.element.append(mainContainer, sidebarContainer);

    } else if (window.innerWidth < 992) {

      mainContent.forEach((mainDiv) => {
        this.element.appendChild(mainDiv);
      });

      sidebarContent.forEach((sidebarDiv) => {
        this.element.appendChild(sidebarDiv);
      });

      mainContainer.remove();
      sidebarContainer.remove();
    }
  }

}
