import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["modal", "card", "seeMore", "close"];

  openModal() {
    this.modalTarget.style.display = 'flex';
  }

  closeModal() {
    this.modalTarget.style.display = 'none';
    this.cardTargets.forEach((card) => {
      card.style.border = '1px solid var(--border)';
      if (card.nextElementSibling) card.nextElementSibling.remove();
    });
  }

  select() {
    if (!this.seeMoreTargets.includes(event.target.parentElement) && !this.seeMoreTargets.includes(event.target) && !this.closeTargets.includes(event.target)) {
      const selectedCard = event.currentTarget.dataset.index;
      this.cardTargets.forEach((card, index) => {
        if (index == selectedCard) {
          card.style.border = '2px solid var(--primary)';
          const confirmBtn = '<button class="btn btn-primary mt-3" data-action="RSE-modal#closeModal">Valider mon choix</button>'
          if (!card.nextElementSibling) card.insertAdjacentHTML('afterend', confirmBtn);
        }
        else {
          card.style.border = '1px solid var(--border)';
          if (card.nextElementSibling) card.nextElementSibling.remove();
        }
      });
    }
  }
}
