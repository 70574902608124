import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  reloadEvents(event) {
    if (event.detail.success) {
      this.dispatch("new-event-added")
      this.dispatch("close-side-loader")
    }
  }
}
