import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  connect() {
    console.log("BOOND CONNECTED")
    this.element.wobeeboond = window.BoondManager.init({
      targetOrigin: '*',
      // mainDivId: 'main-div',
      // parentID: this.element.dataset.iframeid
    }).then( () => {
      console.log('BOOND MANAGER IFRAME AFTER INIT')
      window.BoondManager.setAutoResize();
    })
  }

  setAutoResize() {
    console.log("BOOND AUTO RESIZE")
    // alert('BOOND AUTO RESIZE')
    window.BoondManager.setAutoResize()
    // this.element.wobeeboond
  }
}
