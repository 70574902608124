import { Controller } from "@hotwired/stimulus"
import { FetchRequest } from '@rails/request.js'

export default class extends Controller {
  async getOembed(event) {
    event.preventDefault()

    console.log('getOembed function from Oembed Controller')

    const request = new FetchRequest('post', '/oembed', { body: JSON.stringify({ url: event.target.value }), responseKind: 'turbo-stream' })
    const response = await request.perform()
    if (response.ok) {
      const body = await response.text
      let wrapper = document.createElement('div')
      wrapper.innerHTML = body
      let oembedHtml = wrapper.firstChild.firstChild.content
      document.getElementById('resource_oembed_html').value = oembedHtml.firstElementChild.outerHTML
    }
  }
}


// https://www.youtube.com/watch?v=SMpYFIS-ksU
// https://stackoverflow.com/questions/6671442/set-ruby-variable-if-it-is-not-already-defined
// https://www.instagram.com/p/CUaIOvYIPfG/
// https://giphy.com/gifs/nfl-sports-football-sport-89vTkz3X30RmRTMeEo
