import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = {
    url: String,
    filename: String,
  }

  static targets = ["button"]

  initialize() {
    console.log('save to drive controller initialized')
    window.___gcfg = {
      parsetags: 'explicit'
    }
  }

  connect() {
    console.log('save to drive controller connected')
    gapi.savetodrive.render(this.buttonTarget, {
      src: this.urlValue,
      filename: this.filenameValue,
      sitename: 'Wobee'
    });
  }

  saveToDrive(event) {
    event.preventDefault()
    console.log('save to drive controller clicked')
  }
}
